import Table from '@veneer/core/dist/scripts/table'
import styled from 'styled-components'

export const TableStyled = styled(Table)`
  th {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
    line-height: 20px;
  }
  td {
    font-size: 16px;
    line-height: 20px;
    &:first-child {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    padding-top: 16px;
    padding-bottom: 16px;
  }
`
//emptyTable
export const EmptyTableContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(32px + 20px);
  padding: 16px 12px;
  vertical-align: inherit;
  white-space: normal;
  word-break: break-word;
  background-color: #ffffff;
  height: 100px;
  justify-content: center;
`

// emptyTable__icon
export const EmptyTableIcon = styled.div`
  height: 36px;
  width: 36px;
  background-color: #2121211a;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 8px;
`

// emptyTable__content
export const EmptyTableContent = styled.h6`
  color: #404040;
  font-family: ${({ font }) => font};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`
