import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useI18n } from '@jarvis/react-portal-addons'
// Import Hooks
import useGetDeviceDetails from '../../hooks/useGetDeviceDetails'
// Import Context
import GeneralContext from '../../contexts/GeneralContext'
import { PrinterContext } from '../../provider/PrinterProvider'
// Import Consts
import {
  filterOptions,
  getEnvConfig,
  PrinterListBreadcrumData,
  columns,
  getDateAndTime
} from '../../static/consts'
// Import Component
import PrinterListStatus from '../PrinterListStatus'
import PrinterListTable from '../PrinterListTable'
import LastUpdate from '../LastUpdate'
import SearchTableData from '../SearchTableData'
import SubHeader from '../SubHeader'
import EmptyTable from '../EmptyTable'

import { PrinterListContainer, DeviceSecondaryName, DeviceName } from './styles'
import {
  PrinterListLastUpdatedButton,
  PrinterListScreenDisplayed,
  publishEvent
} from '../../utils/analytics'

const PrinterList = (props) => {
  const { t } = useI18n()
  const { stack } = useContext(GeneralContext)
  const { setBreadcrumbs } = useContext(PrinterContext)
  const config = getEnvConfig(stack)
  const { fetchDevicesAPI } = useGetDeviceDetails()

  const [loading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [deviceOptions, setDeviceOptions] = useState(filterOptions)
  const [pageSize, setPageSize] = useState(10)
  const [totalItems, setTotalItems] = useState(0)
  const [dateAndTime, setDateAndTime] = useState('')

  const [searchVal, setSearchVal] = useState('')
  const [renderError, setRenderError] = useState(false)

  // To call reloadDeviceList Function when the deviceOptions hooks value updates
  useEffect(() => {
    const mounted = { current: true }
    reloadDeviceList(mounted)
    return () => {
      mounted.current = false
    }
  }, [deviceOptions])

  // // To set breadcrumbs Data
  useEffect(() => {
    setBreadcrumbs(PrinterListBreadcrumData(t))
  }, [setBreadcrumbs])

  useEffect(() => {
    publishEvent(PrinterListScreenDisplayed)
  }, [])

  const getDeviceConnectionState = (value) => {
    return value === 'online'
      ? t('smbDevices.connectionStatus.online', 'Connected')
      : t('smbDevices.connectionStatus.offline', 'Not connected')
  }
  // Map all the device data
  const createTableData = (devices) => {
    return devices.map((device, index) => {
      const deviceMetaData =
        device?.solutionMetadata && device?.solutionMetadata
      const deviceStatus =
        device?.solutionMetadata &&
        device?.solutionMetadata[config?.clientId]?.result
      const deviceStatusCount =
        device?.solutionMetadata &&
        device?.solutionMetadata[config?.clientId]?.outOfComplianceCount
      const deviceConnectionState = device?.status?.connectionState
      const deviceFriendlyName = device?.identity?.friendlyName
      const deviceName = device?.identity?.makeAndModel?.name
      return {
        deviceId: device?.deviceId,
        deviceName: (
          <Link
            to={`/solutions/smartsecurity/${device.deviceId}`}
            style={{ textDecoration: 'none' }}
          >
            <div>
              <DeviceName
                data-testid={`device-name-${device.deviceId}`}
                className="table__text table__text--link"
                id={device.deviceId}
              >
                {deviceFriendlyName ? deviceFriendlyName : deviceName}
              </DeviceName>
              <DeviceSecondaryName
                data-testid={`device-secondaryname-${deviceName}`}
                className="table__text table__text--secondary"
              >
                {deviceName}
              </DeviceSecondaryName>
            </div>
          </Link>
        ),
        location: (
          <div data-testid={`device-location-${device?.identity?.location}`}>
            {device?.identity?.location ? device?.identity?.location : '-'}
          </div>
        ),
        deviceConnectionState: (
          <div>
            <span
              data-testid={`device-connectcolor-${getDeviceConnectionState(
                deviceConnectionState
              )}`}
              className={`pr-list__status-indicator ${
                deviceConnectionState === 'online'
                  ? 'pr-list__status-indicator--online'
                  : 'pr-list__status-indicator--offline'
              }`}
            ></span>
            <span
              data-testid={`device-${getDeviceConnectionState(
                deviceConnectionState
              )}`}
            >
              {getDeviceConnectionState(deviceConnectionState)}
            </span>
          </div>
        ),
        deviceStatus: (
          <div className="icon-text-wrapper">
            <PrinterListStatus
              deviceStatus={deviceStatus}
              deviceStatusCount={deviceStatusCount}
              deviceConnectionState={deviceConnectionState}
              deviceMetaData={deviceMetaData}
            />
          </div>
        )
      }
    })
  }

  // API calls of devicecache API
  const reloadDeviceList = (mounted = { current: true }) => {
    setLoading(true)
    if (searchVal.length < 3) delete deviceOptions.search
    fetchDevicesAPI(deviceOptions)
      .then((response) => {
        if (mounted.current) {
          setTotalItems(response.count)
          setRenderError(false)
          if (response.count) {
            if (stack !== 'local') {
              setTableData(createTableData(response.devices)) // formatted data for the table
            } else {
              const pagedData = createTableData(response.devices).slice(
                (currentPage - 1) * pageSize,
                (currentPage - 1) * pageSize + pageSize
              )
              setTableData(pagedData)
            }
            setLoading(false)
            setDateAndTime(getDateAndTime(t))
          } else {
            setTableData([])
            setLoading(false)
          }
        }
      })
      .catch((e) => {
        console.log(e)
        setTotalItems(0)
        setRenderError(true)
        setDateAndTime(getDateAndTime(t))
        setLoading(false)
        setTableData([])
      })
  }

  // Show data when the page number changes
  const handlePageChange = (page) => {
    setCurrentPage(page)
    setDeviceOptions({ ...deviceOptions, offset: page - 1 })
  }

  // Change Page number
  const handlePageSizeChange = (event, option) => {
    if (totalItems) {
      setPageSize(option.value)
      setDeviceOptions({
        ...filterOptions,
        limit: option.value,
        search: searchVal
      })
    }
  }

  const handleSearch = (e) => {
    setSearchVal(e)
    setCurrentPage(1)
    setDeviceOptions({
      ...deviceOptions,
      search: e,
      offset: 0
    })
  }

  const handleClear = () => {
    setSearchVal('')
    delete deviceOptions.search
    setDeviceOptions({ ...deviceOptions })
  }

  const handleSort = (_0, sort) => {
    if (tableData.length) {
      searchVal.length < 3 && delete deviceOptions.search
      setCurrentPage(1)
      setDeviceOptions({
        ...deviceOptions,
        offset: 0,
        sortBy: sort.id,
        order: sort.type
      })
    }
  }

  return (
    <PrinterListContainer>
      <SubHeader />
      <LastUpdate
        handleReloadPage={() => {
          reloadDeviceList()
        }}
        dateAndTime={dateAndTime}
        updateLoader={loading}
        event={PrinterListLastUpdatedButton}
      />
      {!renderError ? (
        <SearchTableData
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          handleSearch={handleSearch}
          handleClear={handleClear}
        />
      ) : tableData.length === 0 ? (
        <SearchTableData
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          handleSearch={handleSearch}
          handleClear={handleClear}
        />
      ) : (
        <></>
      )}
      {!renderError ? (
        <PrinterListTable
          columns={columns(t)}
          tableData={tableData}
          loading={loading}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          totalItems={totalItems}
          handleSort={handleSort}
          deviceOptions={deviceOptions}
        />
      ) : tableData.length === 0 ? (
        <PrinterListTable
          columns={columns(t)}
          tableData={[]}
          loading={loading}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          pageSize={pageSize}
          totalItems={totalItems}
          handleSort={handleSort}
          deviceOptions={deviceOptions}
        />
      ) : (
        <EmptyTable
          handleReloadPage={() => {
            reloadDeviceList()
          }}
        />
      )}
    </PrinterListContainer>
  )
}

export default PrinterList
