import React, { useContext } from 'react'
// TODO: font change: add
// import { TableStyled } from './styles'
import styled from 'styled-components'
import GeneralContext from '../../contexts/GeneralContext'
import { pickVeneerVersion } from '../../font'
import {
  EmptyTableContainer,
  EmptyTableIcon,
  EmptyTableContent
} from './styles'
import { useI18n } from '@jarvis/react-portal-addons'

const PrinterListTable = (props) => {
  const {
    columns,
    tableData,
    loading,
    currentPage,
    handlePageChange,
    handlePageSizeChange,
    pageSize,
    totalItems,
    handleSort,
    deviceOptions
  } = props
  // TODO: font change: delete
  const { fontNewEnable } = useContext(GeneralContext)
  const VeneerCore = pickVeneerVersion(fontNewEnable)
  const { Table } = VeneerCore.core
  const { fontFamilyRegular } = VeneerCore.token
  const { IconWarningAlt } = VeneerCore.core
  const { t } = useI18n()

  const TableStyled = styled(Table)`
    tr {
      font-family: ${fontFamilyRegular};
    }
    th {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 14px;
      line-height: 20px;
    }
    td {
      font-family: ${fontFamilyRegular};
      font-size: 16px;
      line-height: 20px;
      &:first-child {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      padding-top: 16px;
      padding-bottom: 16px;
    }
  `
  return (
    <TableStyled
      customNoItems={
        <EmptyTableContainer>
          <EmptyTableIcon>
            <IconWarningAlt size={24} data-testid="empty-warning-icon" />
          </EmptyTableIcon>
          <EmptyTableContent font={fontFamilyRegular} data-testid="load-err">
            {t('smbDevices.deviceSecurityStatus.noItems', 'No Items Found')}
          </EmptyTableContent>
        </EmptyTableContainer>
      }
      data-testid="device-list-table"
      columns={columns}
      data={tableData}
      loading={loading}
      loadingDataLength={5}
      onSort={handleSort}
      preferences={{
        sortBy: {
          id: deviceOptions?.sortBy,
          type: deviceOptions?.order
        },
        width: [
          { columnId: 'deviceName', width: 243 },
          { columnId: 'location', width: 243 },
          { columnId: 'deviceConnectionState', width: 243 },
          { columnId: 'deviceStatus', width: 243 }
        ]
      }}
      pagination={{
        currentPage,
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
        pageSize,
        totalItems,
        pageSizeOptions: [
          { value: 10 },
          { value: 25 },
          { value: 50 },
          { value: 100 }
        ]
      }}
    />
  )
}

export default PrinterListTable
